import componentsCart from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/cart"
import componentsCheckoutCartContainer from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/checkout/cartContainer"
import componentsCheckoutOrderItem from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/checkout/orderItem"
import componentsCheckoutShippingAndDelivery from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/checkout/shippingAndDelivery"
import componentsKlarna from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/klarna"
import componentsProductList from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/product/list"
import componentsProductUpsell from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/product/upsell"
import componentsVfDialog from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/vf/dialog"
import componentsVfMediaGallery from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/vf/mediaGallery"
import componentsVfPill from "/opt/build/repo/brands/timberland/regions/emea/config/app/components/vf/pill"
import pagesOrderStatus from "/opt/build/repo/brands/timberland/regions/emea/config/app/pages/orderStatus"
import pagesPdp from "/opt/build/repo/brands/timberland/regions/emea/config/app/pages/pdp"
    
export default {"components":{"cart":componentsCart,"checkout":{"cartContainer":componentsCheckoutCartContainer,"orderItem":componentsCheckoutOrderItem,"shippingAndDelivery":componentsCheckoutShippingAndDelivery},"klarna":componentsKlarna,"product":{"list":componentsProductList,"upsell":componentsProductUpsell},"vf":{"dialog":componentsVfDialog,"mediaGallery":componentsVfMediaGallery,"pill":componentsVfPill}},"pages":{"orderStatus":pagesOrderStatus,"pdp":pagesPdp}}
