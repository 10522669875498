import type { PagesConfig } from '#types/config/pages'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    formsWrapper: 'flex center',
    trackOrderForm: 'md:max-w-lg lg:max-w-xl',
  },
  showLoginCta: true,
  showLoginForm: false,
} satisfies RecursivePartial<PagesConfig['orderStatus']>
