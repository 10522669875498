import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  brandClasses: {
    klarnaPayments: 'mt-2',
    paypalPayments: 'mt-2'
  },
  gallery: {
    showShopTheLookButtonOnDesktop: true
  },
  showStickyHeader: true
} satisfies RecursivePartial<PdpConfig>
