import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    panel: {
      header: 'subtitle-2'
    },
    modal: {
      header: 'subtitle-2'
    }
  }
} satisfies RecursivePartial<VfConfig['dialog']>
