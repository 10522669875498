import type { ProductConfig } from '#types/config/components/product'

export default {
  brandClasses: {
    container: 'py-4 px-6 bg-grey-90 relative',
    details: {
      button: '',
      link: 'body-3 underlined absolute bottom-4',
      price: 'body-3',
      text: 'subtitle-4 fw-medium'
    },
    title: 'subtitle-3 fw-medium'
  },
  checkboxSize: 'sm',
  dialogOptions: {
    type: {
      sm: 'panel',
      md: 'modal'
    }
  },
  image: {
    height: 110,
    width: 94
  }
} satisfies ProductConfig['upsell']
