import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    filterAndSortCta: 'px-10',
    noResults: 'mb-14 mt-12 text-center fw-bold lg:mt-10 title-2'
  },
  scrollToElementOptions: { offset: 0 }
} satisfies RecursivePartial<ProductConfig['list']>
